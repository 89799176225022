<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>试验样品信息</span>
      <el-button
        v-if="handleType !== 'find'"
        type="primary"
        style="float:right;font-size:14px;"
        @click="createSample"
        >添加样品</el-button
      >
    </div>
    <el-row :gutter="20">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column prop="Name" label="样品名称" min-width="150" />
        <el-table-column prop="Num" label="样品编号" min-width="150" />
        <el-table-column prop="Amount" label="样品数量" min-width="150" />
        <el-table-column prop="Model" label="样品型号" min-width="150" />
        <el-table-column prop="Batch" label="样品批次" min-width="100" />
        <!-- <el-table-column prop="pinout" label="样品管脚图" min-width="100" /> -->
        <el-table-column
          prop="manufacturer"
          label="样品生产厂家"
          min-width="100"
        />
        <el-table-column
          v-if="handleType !== 'find'"
          fixed="right"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleClick('edit', scope.row, scope.$index)"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleClick('delete', scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <SampleDialog :visible.sync="visible" @submit="submit" ref="sampleDialog" />
  </el-card>
</template>

<script>
import SampleDialog from "@/views/outline/components/dialog/sampleDialog";
import { isUndefined } from "@/utils";
export default {
  props: {
    handleType: {
      type: String,
      default: "create"
    }
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      visible: false
    };
  },
  methods: {
    handleClick(type, row, index) {
      if (type === "edit") {
        this.$refs.sampleDialog.formData = { ...row };
        this.$refs.sampleDialog.formData.index = index;
        this.visible = true;
      } else {
        this.$confirm("您确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          this.tableData.splice(index, 1);
        });
      }
    },
    createSample() {
      this.$refs.sampleDialog.formData = {};
      this.visible = true;
    },
    // 确定添加
    submit(data) {
      if (isUndefined(data.index)) {
        this.tableData.push(data);
      } else {
        this.$set(this.tableData, data.index, data);
      }
    }
  },
  components: {
    SampleDialog
  }
};
</script>

<style lang="scss" scoped>
.box-card {
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
}
</style>
