<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>试验设备信息</span>
    </div>
    <el-row :gutter="20">
      <div class="clearfix">
        <span style="font-weight:normal;font-size:14px;">委托方自带设备</span>
        <el-button
          v-if="handleType !== 'find'"
          type="primary"
          style="float:right;font-size:14px;"
          @click="createDevice('enturst')"
          >添加设备</el-button
        >
      </div>
      <el-table
        v-loading="entrustTableLoading"
        :data="entrustTableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column prop="applyEquName" label="设备名称" min-width="150" />
        <el-table-column
          prop="applyEquModel"
          label="设备型号"
          min-width="150"
        />
        <el-table-column
          prop="applyEquNumber"
          label="设备编号或管理编号"
          min-width="150"
        />
        <el-table-column prop="applyEquDate" label="计量有效期" min-width="150">
          <template slot-scope="{ row }">{{
            row.applyEquDate | parseTime
          }}</template>
        </el-table-column>
        <el-table-column
          v-if="handleType !== 'find'"
          fixed="right"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleClick('edit', scope.row, scope.$index)"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleClick('delete', scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row :gutter="20">
      <div class="clearfix">
        <span style="font-weight:normal;font-size:14px;">理化所提供设备</span>
        <el-button
          v-if="handleType !== 'find'"
          type="primary"
          style="float:right;font-size:14px;"
          @click="createDevice('lhs')"
          >添加设备</el-button
        >
      </div>
      <el-table
        v-loading="lhsTableLoading"
        :data="lhsTableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
          prop="pyhsicalEquName"
          label="设备名称"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <el-input
              v-if="row.edit"
              v-model="row.pyhsicalEquName"
              @blur="row.edit = false"
            />
            <span v-else>{{ row.pyhsicalEquName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pyhsicalEquTarget"
          label="设备技术指标、精度等要求"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <el-input
              v-if="row.edit"
              v-model="row.pyhsicalEquTarget"
              @blur="row.edit = false"
            />
            <span v-else>{{ row.pyhsicalEquTarget }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="handleType !== 'find'"
          fixed="right"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="scope.row.edit = !scope.row.edit">{{
              scope.row.edit ? "取消" : "编辑"
            }}</el-button>
            <el-button
              type="text"
              @click="handleClick('delete', scope.row, scope.$index, true)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <LhsDeviceDialog @submit="lhsSubmit" ref="LhsDeviceDialog" />
    <EntrustDeviceDialog @submit="entrustSubmit" ref="EntrustDeviceDialog" />
  </el-card>
</template>

<script>
import EntrustDeviceDialog from "@/views/outline/components/dialog/entrustDeviceDialog";
import LhsDeviceDialog from "@/views/outline/components/dialog/lhsDeviceDialog";
export default {
  props: {
    handleType: {
      type: String,
      default: "create"
    }
  },
  data() {
    return {
      // 委托方数据
      entrustTableData: [],
      // 理化所数据
      lhsTableData: [],
      entrustTableLoading: false,
      lhsTableLoading: false
    };
  },
  methods: {
    handleClick(type, row, index, isLhs) {
      if (type === "edit") {
        this.$refs.EntrustDeviceDialog.formData = { ...row };
        this.$refs.EntrustDeviceDialog.formData.index = index;
        this.$refs.EntrustDeviceDialog.isEdit = true;
        this.$refs.EntrustDeviceDialog.applyEquDateRange = this.$refs.EntrustDeviceDialog.formData.applyEquDate.split(
          ","
        );
        this.$refs.EntrustDeviceDialog.open();
      } else {
        this.$confirm("您确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          if (isLhs) {
            this.lhsTableData.splice(index, 1);
          } else {
            this.entrustTableData.splice(index, 1);
          }
        });
      }
    },
    createDevice(type) {
      if (type === "lhs") {
        this.$refs.LhsDeviceDialog.open();
      } else {
        this.$refs.EntrustDeviceDialog.formData = {};
        this.$refs.EntrustDeviceDialog.isEdit = false;
        this.$refs.EntrustDeviceDialog.applyEquDateRange = [];
        this.$refs.EntrustDeviceDialog.open();
      }
    },
    // 确定添加
    lhsSubmit(data) {
      data = data.map(item => {
        this.$set(item, "edit", false);
        return item;
      });
      this.lhsTableData.push(...data);
      console.log(this.lhsTableData);
    },
    entrustSubmit(data, isEdit) {
      if (isEdit) {
        this.$set(this.entrustTableData, data.index, data);
      } else {
        this.entrustTableData.push(data);
      }
    }
  },
  filters: {
    parseTime(value) {
      let times = value.split(",");
      return times[0] + "~" + times[1];
    }
  },
  components: {
    LhsDeviceDialog,
    EntrustDeviceDialog
  }
};
</script>

<style lang="scss" scoped>
.box-card {
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
}
</style>
