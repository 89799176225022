<template>
  <el-dialog
    center
    width="800px"
    ref="form"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleCancel"
  >
    <el-form ref="form" :model="formData" :rules="formRules">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="样品名称" prop="Name">
            <el-input v-model="formData.Name" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="样品编号" prop="Num">
            <el-input
              v-model="formData.Num"
              placeholder="请输入"
              @input="getSampleCount"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="样品数量" prop="Amount">
            <el-input
              type="number"
              v-model="formData.Amount"
              placeholder="请输入"
              v-positive-int
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="样品型号" prop="Model">
            <el-input v-model="formData.Model" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="样品批次" prop="Batch">
            <el-input v-model="formData.Batch" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="样品工艺信息" prop="TechniqueInfo">
            <el-input v-model="formData.TechniqueInfo" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item label="样品的质量等级">
            <el-input v-model="formData.qualityLevel" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="样品生产厂家">
            <el-input v-model="formData.manufacturer" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="样品实现的功能">
            <el-input v-model="formData.function" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="样品管脚图">
            <Upload v-model="formData.pinout" />
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="样品封装图">
            <Upload v-model="formData.packageDiagram" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="样品附件">
            <FileUpload v-model="formData.attachment" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload";
import FileUpload from "@/components/FileUpload";
import { isUndefined } from "@/utils";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        attachment: "",
        pinout: "",
        packageDiagram: ""
      },
      formRules: {
        // 样品规则
        Name: [{ required: true, message: "请输入样品名称", trigger: "blur" }],
        Num: [{ required: true, message: "请输入样品编号", trigger: "blur" }],
        Amount: [{ required: true, message: "请输入样品数量", trigger: "blur" }]
      }
    };
  },
  computed: {
    title() {
      if (isUndefined(this.formData.index)) {
        return "添加样品信息";
      }
      return "编辑样品信息";
    }
  },
  methods: {
    // 提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let formData = JSON.stringify(this.formData);
          formData = JSON.parse(formData);
          if (!this.formData.attachment) {
            this.formData.attachment = [];
          }
          this.$emit("submit", formData);
          this.handleCancel();
        } else {
          this.$message.error("请填写必要字段");
          return false;
        }
      });
    },
    // 获取样品数量
    getSampleCount(str) {
      if (str.indexOf("#") !== -1) {
        // 固定以#开头的
        this.formData.Amount = this.patch("#", str) || 0;
      }
    },
    //正则匹配字符串
    patch(re, s) {
      re = eval("/" + re + "/ig");
      return s.match(re).length;
    },
    // 取消
    handleCancel() {
      this.$emit("update:visible", false);
    }
  },
  directives: {
    // 输入框正数限制
    positiveInt: {
      bind: function(el) {
        el.handler = function() {
          let input = el.querySelector("input");
          input.value = Number(input.value.replace(/\D+/, ""));
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function(el) {
        el.removeEventListener("input", el.handler);
      }
    }
  },
  components: {
    Upload,
    FileUpload
  }
};
</script>
