<template>
  <el-dialog
    center
    width="800px"
    ref="form"
    title="添加理化所设备"
    :visible.sync="visible"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      border
      stripe
      style="width: 100%;margin:20px 0"
      :header-cell-style="{ background: '#f8f8f9' }"
      @selection-change="handleSelectionChange"
      ref="lhsTable"
    >
      <el-table-column
        :selectable="isDisabled"
        type="selection"
        align="left"
        width="40"
      />
      <el-table-column
        prop="pyhsicalEquName"
        label="设备名称"
        min-width="150"
      />
      <el-table-column
        prop="pyhsicalEquTarget"
        label="设备技术指标、精度等要求"
        min-width="150"
      />
    </el-table>
    <Page
      :total="totalNumber"
      @handleCurrentChangeSub="handleCurrentChangeFun"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deviceList } from "@/api/device";

export default {
  data() {
    return {
      visible: false,
      tableData: [],
      multipleSelection: [],
      tableLoading: true,
      totalNumber: 0
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.deviceList();
    },
    // 添加设备
    submit() {
      this.visible = false;
      this.$emit("submit", this.multipleSelection);
      this.multipleSelection = [];
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChangeFun(page) {
      this.current = page;
      let params = {
        current: page
      };
      this.deviceList(params);
    },
    toCreateOutline() {
      this.$router.push({
        name: "outlineDetail"
      });
    },
    deviceList(params) {
      deviceList(params)
        .then(res => {
          this.tableLoading = false;
          this.tableData = res.data.records;
          this.totalNumber = res.data.total;
          //判断是否该禁用 获取已添加的id
          let ids = [];
          this.$parent.$parent.lhsTableData.forEach(item => {
            ids.push(item.id);
          });
          this.tableData.forEach(item => {
            let result = null;
            result = ids.find(id => {
              return id === item.id;
            });
            if (result) {
              item.disabled = true;
            } else {
              item.disabled = false;
            }
          });
        })
        .catch(err => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    // 是否禁用
    isDisabled(row) {
      if (row.disabled) {
        return 0;
      } else {
        return 1;
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped></style>
