<template>
  <el-upload
    class="upload-demo"
    :action="uploadUrl"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-upload="beforeAvatarUpload"
    :headers="headers"
    :file-list="fileList"
    :on-success="handleAvatarSuccess"
    accept=".doc,.docx,.pdf"
  >
    <el-button size="small" type="primary">点击上传</el-button>
    <div slot="tip" class="el-upload__tip">
      只能上传pdf、docx文件，且不超过20M
    </div>
  </el-upload>
</template>

<script>
import { getToken } from "@/utils";
export default {
  props: {
    fileIds: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "fileIds",
    event: "update"
  },
  watch: {
    fileIds: {
      handler(fileIds) {
        if (fileIds && !this.disabled) {
          this.fileList = fileIds;
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      fileList: [],
      uploadUrl:
        process.env.NODE_ENV === "development"
          ? "/system/upload"
          : window.baseUrl + "/system/upload",
      headers: {
        Authorization: getToken()
      }
    };
  },
  methods: {
    handleAvatarSuccess(res, file, fileList) {
      if (res.data.id) {
        this.handleFileList(fileList);
      }
    },
    handleRemove(file, fileList) {
      this.handleFileList(fileList);
    },
    handlePreview(file) {
      let fileId = file.id;
      let url = window.baseUrl + "/system/download?fileId=" + fileId;
      if (fileId) {
        window.open(url, "__self");
      }
    },
    beforeAvatarUpload(file) {
      const isWordOrPdf =
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword" ||
        file.type === "application/pdf";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isWordOrPdf) {
        this.$message.error("文件仅支持doc、docx、pdf");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isWordOrPdf && isLt20M;
    },
    handleFileList(fileList) {
      let url =
        process.env.NODE_ENV === "development"
          ? "/system/download?fileId="
          : window.baseUrl + "/system/download?fileId=";
      let fileIds = [];
      fileList.forEach(item => {
        if (item.response) {
          fileIds.push({
            name: item.name,
            id: item.response.data.id,
            url: url + item.response.data.id
          });
        } else {
          fileIds.push({
            name: item.name,
            id: item.id,
            url: item.url
          });
        }
      });
      this.$emit("update", fileIds);
    }
  }
};
</script>

<style></style>
