<template>
  <el-dialog
    center
    width="800px"
    ref="form"
    :title="isEdit ? '编辑委托方自带设备' : '添加委托方自带设备'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      label-position="top"
      ref="form"
      :model="formData"
      :rules="formRules"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="设备名称" prop="applyEquName">
            <el-input v-model="formData.applyEquName" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设备型号" prop="applyEquModel">
            <el-input v-model="formData.applyEquModel" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="设备编号或管理编号" prop="applyEquNumber">
            <el-input v-model="formData.applyEquNumber" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计量有效期" prop="applyEquDate">
            <el-date-picker
              v-model="applyEquDateRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getApplyEquDateRange"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      isEdit: false,
      formData: {},
      applyEquDateRange: [],
      formRules: {
        // 样品规则
        applyEquName: [
          { required: true, message: "请输入设备名称", trigger: "blur" }
        ],
        applyEquModel: [
          { required: true, message: "请输入设备型号", trigger: "blur" }
        ],
        applyEquNumber: [
          {
            required: true,
            message: "请输入设备编号或管理编号",
            trigger: "blur"
          }
        ],
        applyEquDate: [
          { required: true, message: "请选择剂量有效期", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    // 提交
    submit() {
      this.$refs.form.validate(valid => {
        console.log(this.formData);
        if (valid) {
          this.visible = false;
          this.$emit("submit", this.formData, this.isEdit);
        } else {
          this.$message.error("请填写必要字段");
          return false;
        }
      });
    },
    //获取日期
    getApplyEquDateRange() {
      let applyEquDate =
        this.applyEquDateRange[0] + "," + this.applyEquDateRange[1];
      this.$set(this.formData, "applyEquDate", applyEquDate);
    }
  }
};
</script>
