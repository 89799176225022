<template>
  <div id="root">
    <span>{{ label }}：</span>
    <el-input
      :disabled="handleType === 'find'"
      v-model="minVal"
      type="number"
      :maxlength="maxlength"
      placeholder="请输入"
      style="width:100px;"
      @input="getFormVal"
      @blur="chekNumber('min')"
    />
    <span class="separator">{{ separator }}</span>
    <el-input
      :disabled="handleType === 'find'"
      v-model="maxVal"
      type="number"
      :maxlength="maxlength"
      placeholder="请输入"
      style="width:100px;"
      @input="getFormVal"
      @blur="chekNumber('max')"
    />
    <span class="remark">{{ unit }}</span>
  </div>
</template>

<script>
export default {
  name: "RangeFormItem",
  model: {
    prop: "value",
    event: "getVal"
  },
  props: {
    /**
     * 标签名称
     */
    label: {
      type: String,
      default: "",
      required: true
    },
    /**
     * 单位
     */
    unit: {
      type: String,
      default: ""
    },
    /**
     * 分隔符
     */
    separator: {
      type: String,
      default: "~"
    },
    /**
     * 操作权限
     */
    handleType: {
      type: String,
      default: ""
    },
    /**
     * 表单长度
     */
    maxlength: {
      type: Number,
      default: 64
    },
    value: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      minVal: "",
      maxVal: ""
    };
  },
  watch: {
    value(v) {
      if (v) {
        let ranges = v.split(",");
        this.minVal = ranges[0];
        this.maxVal = ranges[1];
      }
    }
  },
  methods: {
    getFormVal() {
      let finalVal = "";
      if (this.minVal && this.maxVal) {
        finalVal = this.minVal + "," + this.maxVal;
        this.$emit("getVal", finalVal);
      }
    },
    chekNumber(type) {
      let label = "";
      if (this.unit === "C°") {
        label = "温度";
      } else if (this.unit === "%RH") {
        label = "湿度";
      }
      if (type === "min") {
        // if (parseFloat(this.minVal) >= parseFloat(this.maxVal)) {
        //   this.minVal = "";
        //   this.$message.error(`最低${label}不应大于等于最高${label}`);
        // }
      } else {
        if (parseFloat(this.maxVal) <= parseFloat(this.minVal)) {
          this.maxVal = "";
          this.$message.error(`最高${label}不应小于等于最低${label}`);
        }
      }
    }
  },
  directives: {
    // 输入框正数限制
    positiveInt: {
      bind: function(el) {
        el.handler = function() {
          let inputs = el.getElementsByTagName("input");
          let minInput = inputs[0];
          let maxInput = inputs[1];
          let min = parseFloat(minInput.value);
          let max = parseFloat(maxInput.value);
          if (min > max) {
            maxInput.value = null;
          }
        };
        el.addEventListener("blur", el.handler);
      },
      unbind: function(el) {
        el.removeEventListener("blur", el.handler);
      }
    }
  }
};
</script>

<style scoped>
#root {
  padding: 5px 0;
}
.remark {
  margin: 0 10px;
  color: #c0c4cc;
  font-size: 13px;
}
.separator {
  font-size: 18px;
  margin: 0 5px;
}
</style>
